
import { Fragment, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import { Listing } from "./List.jsx";
import "leaflet/dist/leaflet.css"; 
import L from "leaflet";
import "@changey/react-leaflet-markercluster/dist/styles.min.css";
import "./Map.css";

let DefaultIcon = L.divIcon({ html: '<div class="map-custom-icon"></div>', className: 'map-custom-icon' });
let DefaultIconOnHover = L.divIcon({ html: '<div class="map-custom-icon-black"></div>', className: 'map-custom-icon-black' });


const Map = ({ articles, hoveredPhotoId, updateFilter, mapCenter }) => {
    const [selectedDetail, setSelectedDetail] = useState(null);

    let mapRef = useRef([]);


    const handleMarkerClick = async (article) => {
        try  {
            setSelectedDetail(article);
        } catch(error) {
        }
    };

    return (
        <MapContainer
            className="markercluster-map"
            center={mapCenter}
            zoom={11}
            maxZoom={18}
            style={{ height: "100%", width: "100%" }}

            ref={(map) => {
                if (!mapRef.current[0] && map) {
                    mapRef.current[0] = map;
                    var bounds = map.getBounds();
                    updateFilter('coordinates', [bounds.getNorthWest().lat, bounds.getNorthWest().lng, bounds.getSouthEast().lat, bounds.getSouthEast().lng])
                }
            }}
        >
            <MyComponent updateFilter={updateFilter} selectedDetail={selectedDetail} closePopup={() => setSelectedDetail(null)}></MyComponent>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {articles.map((coord) => (
                <Fragment
                    key={coord.id}>
                    <Marker
                        position={[coord.latitude, coord.longitude]}
                        eventHandlers={{
                            click: () => {
                                handleMarkerClick(coord);
                            },
                        }}
                        key={coord.id}
                        icon={hoveredPhotoId === coord.id ? DefaultIconOnHover : DefaultIcon}
                    >
                        <Popup
                            key={coord.id} closeButton={false}
                        >
                            {selectedDetail ? (
                                    <Listing listing={selectedDetail} setHoveredPhotoId={() => { } } />
                            ) : (<></>)}
                        </Popup>
                    </Marker></Fragment>
            ))}
        </MapContainer>
    );
};


function MyComponent({ updateFilter, selectedDetail, closePopup }) {
    const map = useMapEvents({
        moveend: () => {
            if (!selectedDetail) {
                var bounds = map.getBounds();
                updateFilter('coordinates', [bounds.getNorthWest().lat, bounds.getNorthWest().lng, bounds.getSouthEast().lat, bounds.getSouthEast().lng])
            }
        },
        popupclose: (t) => {
            closePopup()
            var bounds = map.getBounds();
            updateFilter('coordinates', [bounds.getNorthWest().lat, bounds.getNorthWest().lng, bounds.getSouthEast().lat, bounds.getSouthEast().lng])
        },
        click: () => {
            if (selectedDetail) {
                map.closePopup()
            }
        },
        drag: () => {
            if (selectedDetail) {
                map.closePopup()
            }
        }
    })
    return null
}


export default Map;
