// src/App.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { Accordion } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Map from "./Map";
import Tabs from "./Tabs";
import List from "./List";
import Filters from "./Filters";
import { getQueryParams, getInitialFilter, fetchFiltered } from "./utils/Common.jsx"
import { defaultValues, cities } from "./utils/Values.jsx"
import "./Search.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App = ({ city }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [hoveredPhotoId, setHoveredPhotoId] = useState("");
    const [activeTab, setActiveTab] = useState("list");
    const mapCenter = city ? cities[city].center : Object.values(cities)[0].center

    const [articles, setArticles] = useState([]);
    const [cursor, setCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const initialLoadCompleteRef = useRef(false);

    const query = useQuery();
    const navigate = useNavigate()
    const [filters, setFilters] = useState({
        rooms: getInitialFilter(query, 'rooms', defaultValues.rooms),
        minPriceUsd: getInitialFilter(query, 'minPriceUsd', defaultValues.minPriceUsd),
        maxPriceUsd: getInitialFilter(query, 'maxPriceUsd', defaultValues.maxPriceUsd),
        isOwner: getInitialFilter(query, 'isOwner', defaultValues.isOwner),
        search: getInitialFilter(query, 'search', defaultValues.search),
        coordinates: defaultValues.coordinates
    });

    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    const updateFilter = (field, value) => {
        if (field === 'coordinates' && arraysEqual(filters.coordinates, value)) {
            return;
        }
        const updatedFilters = {
            ...filters,
            [field]: value
        };
        setFilters(updatedFilters);

        const params = getQueryParams(updatedFilters);
        navigate({
            pathname: window.location.pathname,
            search: params.toString(),
        }, { replace: true });
    };
    
    useEffect(() => {
        document.title = 'Снять квартиру в Минске на длительный срок';
        document.description = 'Снять квартиру в Минске. Все предложения аренды квартир на интерактивной карте. Квартиры на длительный срок';
      }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchFiltered(filters);
            if(response.data) {
                setArticles(response.data.articles.items);
                setCursor(response.data.articles.endCursor)
                setHasNextPage(response.data.articles.hasNextPage)
            }
            initialLoadCompleteRef.current = true;
        };
        if(isMobile || filters.coordinates?.length) {
            fetchData();
        }
    }, [filters, isMobile]);

    const paginate = useCallback(async () => {
        if(hasNextPage) {
            const response = await fetchFiltered(filters, cursor);
            setArticles((prevItems) => [
                ...prevItems,
                ...response.data.articles.items,
            ]);
            if(response.data) {
                setCursor(response.data.articles.endCursor)
                setHasNextPage(response.data.articles.hasNextPage)
            }
        }
    }, [filters, cursor, hasNextPage]);

    return (
        <>
            {isMobile ? (
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Фильтры</Accordion.Header>
                        <Accordion.Body><Filters filters={filters} updateFilter={updateFilter} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : (
                <Filters filters={filters} updateFilter={updateFilter} />
            )}
            <div className="app">
                {isMobile && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />}
                <div className={`content-container ${isMobile ? "" : "desktop"}`}>
                    {isMobile ? (
                        activeTab === "map" ? (
                            <div className="map-view">
                                <Map
                                    articles={articles}
                                    hoveredPhotoId={hoveredPhotoId}
                                    updateFilter={updateFilter}
                                    mapCenter={mapCenter}
                                />
                            </div>
                        ) : (
                            <List
                                    articles={articles}
                                    initialLoadCompleteRef={initialLoadCompleteRef}
                                    paginate={paginate}
                                    setHoveredPhotoId={setHoveredPhotoId}
                            />
                        )
                    ) : (
                        <>
                            <div className="half-screen">
                                <List
                                    articles={articles}
                                    initialLoadCompleteRef={initialLoadCompleteRef}
                                    paginate={paginate}
                                    setHoveredPhotoId={setHoveredPhotoId}
                                />
                            </div>
                            <div className="half-screen">
                                    <Map
                                        articles={articles}
                                        hoveredPhotoId={hoveredPhotoId}
                                        updateFilter={updateFilter}
                                        mapCenter={mapCenter}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default App;
