import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
          <Link to="." className="logo" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <img src="/logo512.png" alt="Logo" style={{ height: '30px', marginRight: '8px' }} />
            Domanet.by
            </Link>
    </div>
  );
};

export default Header;