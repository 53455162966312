export const rooms = ["1", "2", "3", "4+", "ком."];


export const cities = {
    "Минск": {
        coordinates: [54.139110028283994, 27.18429565429688, 53.65928863690086, 27.815322875976566],
        center: [53.9, 27.5]
    },
    "Гродно": {
        coordinates: [],
        center: [49.9, 27.5]
    },
    "Могилёв": {
        coordinates: [],
        center: [51.9, 27.5]
    },
    "Брест": {
        coordinates: [],
        center: [53.9, 20.5]
    }
}

export const defaultValues = {
    city: '',
    rooms: [],
    minPriceUsd: '',
    maxPriceUsd: '',
    coordinates: null,
    isOwner: false,
    search: ''
};